<template>
    <div class="container">
        <img src="@/assets/img/dot.png" alt="" class="dot t-dot" />
        <div class="content">
            <img src="@/assets/img/guanyu.png" alt="" class="guanyu" />
            <div class="dd">
                <div class="title">关于我们</div>
                <div class="sub">给您丰富的内容，和强大的变现效率</div>
                <div class="desc">
                    北京福旺乐游科技有限公司成⽴于2020年，专注于海外
                    市场内容⽣产及变现，依托新兴技术与敏锐的市场的洞察
                    ⼒进⾏产品迭代，正努⼒成为全球卓越、国内领先的移
                    动互联⽹内容⽣态综合服务商。
                </div>
                <div class="desc">
                    经过几年的时间，逐步发展出休闲游戏等业务线。
                </div>
                <div class="desc">
                    ⽬前公司产品在全球覆盖⽤户超2亿，依托⼴告与内容双
                    核⼼，与⼩⽶、OPPO、华为、传⾳等在多个领域建⽴了
                    合作关系，致⼒于通过内容+⼴告的形式帮助更多媒体及
                    开发者实现共赢。
                </div>
            </div>
        </div>
        <img src="@/assets/img/dot.png" alt="" class="dot b-dot" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Home'
});
</script>

<style lang="less" scoped>
.container {
    background: #fff;
    height: 900px;
    overflow: hidden;
    position: relative;

    .dd {
        width: 500px;
        margin-left: 53px;
    }

    .title {
        font-size: 30px;
        font-family: pf1;
        font-weight: 400;
        color: #683af2;
    }

    .dot {
        position: absolute;
        height: 314px;
    }

    .sub {
        font-size: 40px;
        font-family: pf1;
        font-weight: 400;
        color: #222629;
        line-height: 57px;
        margin-top: 27px;
        margin-bottom: 35px;
    }

    .desc {
        font-size: 20px;
        font-family: pf1;
        font-weight: 400;
        line-height: 40px;
        color: #707072;
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .t-dot {
        right: 427px;
        top: -250px;
    }

    .b-dot {
        right: -150px;
        bottom: -150px;
    }

    .content {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .guanyu {
        width: 808px;
        height: 645px;
    }
}
</style>
