<template>
    <div class="container" id="contact">
        <img src="@/assets/img/logo.png" alt="" />
        <div class="address">公司地址:北京市海淀区紫金数码园3号楼</div>
        <div class="mail">联系邮箱:connect@nineday.net</div>
        <div class="line"></div>
        <div class="desc">京ICP备2021014276号-1</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Home'
});
</script>

<style lang="less" scoped>
.container {
    background: #6543d6;
    height: 430px;
    padding: 74px 0 40px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;

    .address {
        font-size: 23px;
        font-family: pf1;
        font-weight: 400;
        color: #ffffff;
        margin-top: 47px;
        margin-bottom: 34px;
    }

    .mail {
        font-size: 23px;
        font-family: pf1;
        font-weight: 400;
        color: #ffffff;
    }

    .line {
        width: 837px;
        height: 1px;
        flex-shrink: 0;
        background: #fff;
        margin: 38px 0 17px;
    }

    .desc {
        font-size: 23px;
        font-family: pf1;
        font-weight: 400;
        color: #ffffff;
    }
}
</style>
