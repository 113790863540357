<template>
    <div class="container">
        <div class="content">
            <div class="nav">
                <img src="@/assets/img/logo.png" class="logo" />
                <div class="items">
                    <ul class="item-content">
                        <li v-for="item in navs" class="item" :key="item.label">
                            <a :href="`#${item.key}`" class="mao">
                                {{ item.label }}
                            </a>
                        </li>
                        <li class="item">
                            <a href="#contact" class="mao"> 联系我们 </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="desc">
                <div class="text">
                    <div class="sub sub1">海外互联网</div>
                    <div>
                        <span class="sub sub2">内容生态</span>
                        <span class="sub sub3">综合服务商</span>
                    </div>
                    <div class="tab-nav">
                        <ul class="item-content">
                            <li
                                v-for="item in navs"
                                class="item"
                                :key="item.label"
                            >
                                <a :href="`#${item.key}`" class="mao">
                                    {{ item.label }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <img src="@/assets/img/top-sel.png" class="sel" />
            </div>
        </div>
        <img src="@/assets/img/top-bt.png" class="bt" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Home',
    data() {
        return {
            navs: [
                {
                    label: '休闲游戏',
                    key: 'game'
                }
            ]
        };
    }
});
</script>

<style lang="less" scoped>
.container {
    background: linear-gradient(-20deg, #644bf1, #801690);
    position: relative;
    height: 1080px;

    .tab-nav {
        margin-top: 86px;
        .item,
        .mao {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 169px;
            height: 59px;
            margin-left: 20px;
            color: #4e3d51;
            background: #fff;
            border-radius: 40px;
            transition: all 0.2s;

            &:hover {
                background: #fb991c;
                color: #fff;
                transition: all 0.2s;
            }
        }
    }

    .sub1 {
        margin-bottom: 20px;
    }

    .sub {
        font-size: 91px;
        font-weight: 600;
        color: #ffffff;
    }

    .sub3 {
        color: #fb991c;
    }
    .desc {
        padding-right: 13px;
        padding-left: 7px;
        margin-top: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sel {
        width: 587px;
        height: 613px;
    }

    .item,
    .mao {
        margin-left: 100px;
        font-size: 23px;
        font-weight: 600;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
        &:first-child {
            margin-left: 0;
        }
    }

    .item-content {
        display: flex;
        align-items: center;
    }

    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .content {
        padding: 34px 68px 0 110px;
    }
    .logo {
        width: 200px;
        height: 43px;
    }
    .bt {
        position: absolute;
        bottom: 0;
        left: 0;
        vertical-align: bottom;
        height: 319px;
        width: 100%;
    }
}
</style>
