import { createApp } from 'vue';
import App from './views/Home.vue';
import './registerServiceWorker';
import store from './store';
import 'swiper/css';
import SwiperCore, { Autoplay } from 'Swiper';

SwiperCore.use([Autoplay]);

createApp(App).use(store).mount('#app');
