<template>
    <div class="container" id="game">
        <div class="title">休闲游戏</div>
        <div class="line"></div>
        <div class="desc">
            <div>
                <div class="d-title">变现能力强</div>
                <div class="d-sub">ECPM在各个地区均比行业平均水平高80%+</div>
            </div>
            <div>
                <div class="d-title">用户粘性好</div>
                <div class="d-sub">用户日均游戏次数比行业平均高60%</div>
            </div>
        </div>
        <div class="games">
            <img src="@/assets/img/games.png" />
        </div>
        <div class="list">
            <div class="item">
                <img src="@/assets/img/game1.png" alt="" class="game" />
                <div class="game-title">热门话题游戏运营</div>
                <img src="@/assets/img/lines.png" class="game-line" />
            </div>
            <div class="item">
                <img src="@/assets/img/game2.png" alt="" class="game" />
                <div class="game-title">传统休闲游戏</div>
                <img src="@/assets/img/lines.png" class="game-line" />
            </div>
        </div>
        <div class="list">
            <div class="item">
                <img src="@/assets/img/game3.png" alt="" class="game" />
                <div class="game-title">H5游戏中心</div>
                <img src="@/assets/img/lines.png" class="game-line" />
            </div>
            <div class="item">
                <img src="@/assets/img/game4.png" alt="" class="game" />
                <div class="game-title">H5卖量站</div>
                <img src="@/assets/img/lines.png" class="game-line" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Home'
});
</script>

<style lang="less" scoped>
.container {
    background: #fff;
    padding: 70px 400px 0;
    box-sizing: border-box;

    .game-line {
        width: 100px;
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .game-title {
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        margin: 50px 0 30px;
        color: #000000;
    }

    .list {
        display: flex;
        align-items: center;
        margin-bottom: 180px;
        justify-content: space-between;
    }

    .game {
        width: 300px;
    }

    .games {
        height: 660px;
        margin: 100px auto 180px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .d-sub {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #000000;
    }

    .d-title {
        text-align: center;
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 18px;
        color: #000000;
    }

    .desc {
        display: flex;
        align-items: center;
        padding: 0 50px;
        justify-content: space-between;
    }

    .line {
        height: 1px;
        background: #000;
        margin-top: 60px;
        margin-bottom: 120px;
    }

    .title {
        text-align: center;
        font-size: 70px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
    }
}
</style>
