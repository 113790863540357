import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Module1 = _resolveComponent("Module1")!
  const _component_About = _resolveComponent("About")!
  const _component_Module2 = _resolveComponent("Module2")!
  const _component_Module6 = _resolveComponent("Module6")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Module1),
    _createVNode(_component_About),
    _createVNode(_component_Module2),
    _createVNode(_component_Module6)
  ]))
}