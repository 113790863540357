<template>
    <div class="home">
        <Module1></Module1>
        <About></About>
        <Module2></Module2>
        <!-- <Module3></Module3>
        <Module4></Module4>
        <Module5></Module5> -->
        <Module6></Module6>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Module1 from '@/components/home/module1.vue';
import Module2 from '@/components/home/module2.vue';
// import Module3 from '@/components/home/module3.vue';
// import Module4 from '@/components/home/module4.vue';
// import Module5 from '@/components/home/module5.vue';
import Module6 from '@/components/home/module6.vue';
import About from '@/components/home/about.vue';

export default defineComponent({
    name: 'Home',
    components: {
        Module1,
        Module2,
        // Module3,
        // Module4,
        // Module5,
        Module6,
        About
    }
});
</script>

<style lang="less">
@font-face {
    font-family: 'pf';
    src: url('../assets/fonts/font.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pf1';
    src: url('../assets/fonts/font1.ttf');
    font-weight: normal;
    font-style: normal;
}

html,
body,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
    scroll-behavior: smooth;
}

#app {
    font-family: pf;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
}
</style>
